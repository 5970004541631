export const emailFormat = {
    getHtmlStr(...params) {

        const {
            name,
            username,
            password,
            email,
            phone,
            cityName,
            tradingExp,
            doesUserDmatAccountExist,
            dmatId,
            anyQueries,
        } = params[0]

        return "<p dir=\"&quot;ltr&quot;\">Please See your Details</p><div dir=\"&quot;ltr&quot;\">Name: {NAME},</div><div dir=\"&quot;ltr&quot;\">Phone: {Phone},</div><div dir=\"&quot;ltr&quot;\">City: {City}</div><div dir=\"&quot;ltr&quot;\">Email: {Email}</div><div dir=\"&quot;ltr&quot;\">Trading Experience : {trading_experience}</div><div dir=\"&quot;ltr&quot;\">Does Dmat Id Exist: {does_dmat_exist}</div><div dir=\"&quot;ltr&quot;\">D-MAT ID: {dmat_id}</div><div dir=\"&quot;ltr&quot;\">Queries: {any_queries}</div>".split("{NAME}").join(name).split("{Phone}").join(phone).split("{City}").join(cityName).split("{Email}").join(email).split("{trading_experience}").join(tradingExp).split("{does_dmat_exist}").join(doesUserDmatAccountExist).split("{dmat_id}").join(dmatId).split("{any_queries}").join(anyQueries).split("{USERNAME}").join(username).split("{PASSWORD}").join(password).split('"').join('\\"')
    },
    subject: "15 Days Free Impulse Demo",
    recipentEmail:"algo@impulsetechnical.com"
}