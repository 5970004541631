<template>
  <div class="col py-1 d-flex justify-content-center align-items-center">
    <div class="col px-1 py-1 text-left">
      <div class="CompanyTitleContainer" v-html="title">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormTitle",
  props: ["title"],
  data() {
    return {};
  },
  methods: {},
};
</script>
