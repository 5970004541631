<template>
  <div class="mainFormContainer"
    v-if="
      !formDetails.isFormLoadedError &&
      formDetails.isFormLoaded &&
      registerFormData
    "
  >
    <FormTitle :title="registerFormData.header" />
    <ApiBridgeRegisterForm
      :formDynamicData="registerFormData"
      :formName="formDetails.formName"
    />
  </div>
  <div v-else-if="formDetails.isFormLoadedError && formDetails.isFormLoaded">
    <FormNotFound :errorText="formDetails.formLoadedErrorText" />
  </div>
  <div v-else-if="formDetails.notFormEntered">
    <PageUnderConstruction />
  </div>
  <div v-else-if="!formDetails.isFormLoaded">
    <PageLoader />
  </div>
</template>

<script>
import '../assets/css/Common.css';
import ApiBridgeRegisterForm from "../components/ApiBridgeRegisterForm";
import FormNotFound from "../components/FormNotFound.vue";
import PageLoader from "../components/PageLoader.vue";
import PageUnderConstruction from "../components/PageUnderConstruction.vue";
import FormTitle from "../components/FormTitle.vue";
import '../assets/css/RegisterFormStyle.css';
export default {
  name: "ApiBridgeRegisterFormPage",
  components: {
    FormNotFound,
    FormTitle,
    PageLoader,
    PageUnderConstruction,
    ApiBridgeRegisterForm,
  },
  computed: {
    getDynamicFormData() {
      return JSON.stringify(this.registerFormData);
    },
  },
  data() {
    return {
      registerFormData: "",
      refreshFormDataFetchCount :5,
      api_base_url: process.env.VUE_APP_API_URL,
      demo_api_base_url: process.env.VUE_APP_DEMO_API_URL,
      admin_base_url: process.env.VUE_APP_ADMIN_API_URL,
      proxyUrl: "",
      formDetails: {
        formName: "",
        isFormLoaded: "",
        isFormLoadedError: "",
        formLoadedErrorText: "",
        formTitle: "",
        notFormEntered:false 
      },
    };
  },
  async mounted() {
    const form_name = this.getFormName();
    this.formDetails.formName = form_name;
    

    if (form_name && form_name != "") {
      this.registerFormData = "";
      const formDataValue = await this.fetchFormData();
      if (formDataValue != false) {
        this.registerFormData = formDataValue;
      }
    } else {
      this.formDetails.notFormEntered = true;
      // this.formDetails.isFormLoadedError = true;
      this.formDetails.isFormLoaded = true;
      // this.formDetails.formLoadedErrorText = "Form Not Found";
    }
  },
  methods: {
    getFormName() {
      const pathNameArr = window.location.pathname
        .split("/")
        .filter((path) => path != "");

      if (pathNameArr.length >= process.env.VUE_APP_PATHNAME_INDEX + 1) {
        return pathNameArr[process.env.VUE_APP_PATHNAME_INDEX].trim();
      } else {
        const hostNameArr = window.location.host.split(".");
        if (hostNameArr.length > process.env.VUE_APP_SUBDOMAIN_LENGTH) {
          
          return hostNameArr[0].trim();
        }
      }

      return false;
    },
    async fetchFormData() {
      const fetchFormDataUrl = `${this.proxyUrl}${this.api_base_url}api/forms/Template/${this.formDetails.formName}`;

      this.formDetails.isFormLoaded = false;

      try {
        const formDataResp = await fetch(fetchFormDataUrl, {
          method: "GET",
          redirect: "follow",
        });
        

        const reqStatusCode = formDataResp.status;


        const formDataObj = await formDataResp.json();

        const formDataValue = formDataObj.data; //Need to check whetheer form data

        this.formDetails.isFormLoaded = true;
        if (reqStatusCode != 200) {
          this.formDetails.isFormLoadedError = true;
          this.formDetails.formLoadedErrorText = "Something Went Wrong";
          return false;
        }
        
        if (formDataObj.code != 200) {

          if(this.refreshFormDataFetchCount > 0 && !formDataObj.data.trim().toLowerCase().includes("Data is not available!".toLowerCase())){

                      setTimeout(this.fetchFormData , 100)
            this.refreshFormDataFetchCount--;
            return false ;
          } 
          this.formDetails.isFormLoadedError = true;
          this.formDetails.formLoadedErrorText = "Form Not Found";
          return false;
        }
        if (formDataObj.error) {
          this.formDetails.isFormLoadedError = true;
        }

        this.formDetails.isFormLoaded = true;
        this.formDetails.isFormLoadedError = false;
        return formDataValue;
      } catch (e) {
        this.formDetails.isFormLoaded = true;
        this.formDetails.isFormLoadedError = true;
        this.formDetails.formLoadedErrorText = "Something Went Wrong";
        return false;
      }
    },
  },
};
</script>

<style scoped>
.mainFormContainer{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    line-height: 1.5;
    
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    text-align: left;
}
</style>
