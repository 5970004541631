<template>
  <div class="extraFields">
 
    <div class="form-group row">
      <label for="cityName" class="col-sm-2 col-form-label"
        >City Name <span class="text-danger">*</span>
      </label>
      <div>
        <input  type="text" name="cityName" v-model.lazy="impulseFormData.cityName" class="form-control mx-3" />
      
      </div>
    </div>
    <div class="form-group row">
      <label for="tradingExp" class="col-sm-2 col-form-label"
        >Trading Experience</label
      >
      <div>
        <select
          v-model.lazy="impulseFormData.tradingExp"
          name="tradingExp"
          class="form-control mx-3 custom-select"
        >
          <option>Below 1 year</option>
          <option>1 - 4 years</option>
          <option>5 - 9 years</option>
          <option>10 - 14 years</option>
          <option>Above 15 years</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for=""  class="col-sm-2 col-form-label"
        >Do You have D MAT Account with Impluse Technical</label
      >
      <div>
        <label class="inlineLabel" for="doesDmatAccountExistYes">Yes</label>
        <input type="radio" name="doesDmatAccountExistYes" value="Yes" v-model="impulseFormData.doesUserDmatAccountExist" class="form-control mx-3 inlineCheckBox" />
        <label class="inlineLabel" for="doesDmatAccountExistNo">No</label>
        <input type="radio" name="doesDmatAccountExistNo"  value="No" v-model="impulseFormData.doesUserDmatAccountExist" class="form-control mx-3 inlineCheckBox" />
      </div>
    </div>

    <div v-if="isDmatIdActive" class="form-group row">
      <label for="dmatId" class="col-sm-2 col-form-label">D-MAT ID </label>
      <div>
        <input type="text" v-model.lazy.trim="impulseFormData.dmatId" name="dmatId" class="form-control mx-3" />
      </div>
    </div>
    <div class="form-group row">
      <label for="anyQueries" class="col-sm-2 col-form-label"
        >Any Queries
      </label>
      <div>
        <input type="text" name="anyQueries" v-model.lazy.trim="impulseFormData.anyQueries" class="form-control mx-3" />
      </div>
    </div>
  </div>
</template>

<script>
import {impulseFormData} from "../state/extraFields/stateImplueIframe";
export default {
  name: "ImpluseIframe",

  data() {
    return {
      impulseFormData,
      
    };
  },
  mounted(){
    // console.log(impulseFormData);
    // this.impulseFormData.cityName = "delhi"
  },
  computed:{

     
    isDmatIdActive(){
      return impulseFormData.doesUserDmatAccountExist == 'Yes'
    }
  },
  methods: {
    
  },
};
</script>

<style scoped>
label.inlineLabel {
    min-width: auto;
}
input.form-control.mx-3.inlineCheckBox {
    margin-right: 17px;
}
</style>