<template>
  <div class="bgimg">
  
  <div class="middle">
    <h1>www.apibridge.app is bringing many new possibilities</h1>
    <hr>
   
  </div>
  
</div>
</template>

<script>
export default {

}
</script>

<style>

.bgimg {
  background-image: url('../assets/img/Minimalist-Crumpled-Paper-Simple-Background-Image.jpg');
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
  color: black;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

hr {
  margin: auto;
  width: 40%;
}
</style>