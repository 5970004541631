<template>
  <div class="container1">
    <img alt="Error logo" class="img" src="../assets/img/errorImage1.png" />
    <h1 class="h1">404</h1>
    <div class="para">

      <h2 class="h2">{{ errorText}}</h2>

    </div>
  </div>
</template>
<script>
import "../assets/css/FormNotFound.css"

export default {
  name: "FormNotFound",
  props:["errorText"]
};
</script>
<style scoped>
    .img {
    margin-top: -150px;
    height: 200px;
    }
    .h1 {
    margin: auto;
    font-size: 100px;
    font-weight: 800;
    text-align: center;
    font-family: "Roboto", sans-serif;
    display: flex;
    }
    .para {
    margin: auto;
    font-family: "Roboto", sans-serif;
    }
    .h2 {
    font-size: 35px;
    margin-left: -2rem;
    }
    .container1 {
    width: 300px;
    margin: 0 auto;
    margin-top: 15%;
    }
</style>