import { reactive } from "@vue/composition-api";

export const impulseFormData = reactive({
    cityName: "",
    tradingExp: "",
    doesUserDmatAccountExist: false,
    dmatId: "",
    anyQueries: "",
    validation :{
        isCityNameValid:false
    },
    propsKeyArr: [
        "cityName",
        "tradingExp",
        "doesUserDmatAccountExist",
        "dmatId",
        "anyQueries"
    ],
    conditionalRendering:{
        isLicenseHeaderHidden :true ,
        isBrokerClientIdHidden:true ,
        isBrokerHidden:true ,
        isBrokerPriceHidden:true ,
        isRefferalHidden:true ,
        isLicenseHidden:true,
        isStragedyPlatformHidden:true
    }
    
})