<template>
  <div
    class="
      col
      formSection
      py-1
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <div class="col px-3 py-3 bg-white text-Left">
      <div class="AlgojiRegisterFormContainer">
        <div class="subHeaderText">{{ formPackage.formSubHeaderText }}</div>
        <hr />
        <form
          ref="registerFormElem"
          class="registerForm"
          v-on:submit.prevent="formSubmit"
        >
          <div id="group1">
            <div class="form-group row">
              <label for="name" class="col-sm-2 col-form-label"
                >Name<span class="text-danger">*</span></label
              >
              <div class="">
                <input
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.name"
                  type="text"
                  name="name"
                  id="name"
                />
                <span
                  v-if="validation.isNameError"
                  class="errorText text-danger mx-3"
                  >You need to Enter Your Name</span
                >
              </div>
            </div>

            <div class="form-group row">
              <label for="phoneNumber" class="col-sm-2 col-form-label"
                >Phone <span class="text-danger">*</span></label
              >
              <div>
                <input
                  type="text"
                  name="phoneNumber"
                  v-model.lazy.trim="inputFields.phone"
                  @change="checkIsUserExist"
                  id="phoneNumber"
                  class="form-control mx-3"
                />
                <span
                  v-if="validation.isPhoneError"
                  class="text-danger errorText mx-3"
                  >Please Enter Your 10 digit number</span
                >
                <span v-if="isUserExist" class="text-danger errorText"
                  >User with same phone number exist</span
                >
              </div>
            </div>
            <span
              v-if="
                !(
                  isImpluseForm &&
                  extraFormState.conditionalRendering.isStragedyPlatformHidden
                )
              "
            >
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="SP"
                  >Strategy Platform</label
                >
                <div>
                  <select
                    v-model.lazy.trim="inputFields.stragedyPlatform"
                    id="SP"
                    class="form-control mx-3 custom-select"
                    name="SP"
                  >
                    <option
                      v-for="stragedyPlatformStr in inputFields.stragedyPlatformArr"
                      v-bind:value="stragedyPlatformStr"
                      v-bind:key="stragedyPlatformStr"
                    >
                      {{ stragedyPlatformStr }}
                    </option>
                  </select>
                </div>
              </div>
            </span>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label" for="email"
                >Email<span class="text-danger">*</span></label
              >
              <div>
                <input
                  type="email"
                  v-model.lazy.trim="inputFields.email"
                  @change="emailOtpSend"
                  name="email"
                  id="Email"
                  class="form-control mx-3"
                />
                <span
                  v-if="validation.isEmailError"
                  class="errorText text-danger mx-3"
                  >Please Enter Valid Email</span
                >
                <span v-if="isOtpSend" class="text-success successText mx-3">
                  OTP mail is send.
                </span>
              </div>
            </div>
          </div>

          <div id="group-3">
            <span
              v-if="
                !(
                  isImpluseForm ||
                  extraFormState.conditionalRendering.isLicenseHeaderHidden ||
                  isGoodwillForm
                )
              "
            >
              <div id="LicenseTextContainer">
                <span class="LicenceText">
                  <h3>License Details</h3>
                </span>
              </div>
            </span>

            <!-- License -->
            <span v-if="!(isImpluseForm || isGoodwillForm)">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="license-select"
                  >License</label
                >
                <div>
                  <select
                    class="form-control custom-select mx-3"
                    @change="licenseChange"
                    name="licence"
                    v-model.lazy.trim="inputFields.license"
                    id="licence"
                  >
                    <option
                      v-for="(licence, index) in inputFields.licenceArr"
                      v-bind:value="licence"
                      v-bind:key="index"
                    >
                      {{ licence.license_name }}
                    </option>
                  </select>
                </div>
              </div>
            </span>

            <!-- Broker -->
            <span
              v-if="
                !(
                  isImpluseForm &&
                  extraFormState.conditionalRendering.isBrokerHidden &&
                  isGoodwillForm
                )
              "
            >
              <div
                v-if="
                  (inputFields.license.module_type == 1 ||
                    inputFields.license.module_type > 1) &&
                  inputFields.brokersOptions.length > 0 &&
                  !(
                    isImpluseForm ||
                    isGoodwillForm ||
                    isSASonlineForm ||
                    isProfitmartForm ||
                    isJainamForm ||
                    isRmoneyForm ||
                    isAsthaForm ||
                    isAliceBlueForm
                  )
                "
                class="form-group row dynamicField"
              >
                <label class="col-sm-2 col-form-label" for="broker-select"
                  >Broker</label
                >
                <div>
                  <select
                    v-model.lazy.trim="inputFields.broker"
                    name="broker"
                    id="broker-select"
                    class="form-control custom-select mx-3"
                  >
                    <!-- <option></option> -->
                    <option
                      v-for="(broker, index) in inputFields.brokersOptions"
                      v-bind:value="broker"
                      v-bind:key="index"
                    >
                      {{ broker.BrokerName }}
                    </option>
                  </select>
                  <span
                    v-if="isImportantBrokerSelected"
                    class="text-success successText mx-3"
                    >Approval may be required from your Broker. If any issue
                    your money will be refunded.
                  </span>
                </div>
              </div>
            </span>

            <!-- amount -->
            <span
              v-if="
                !(
                  isImpluseForm &&
                  extraFormState.conditionalRendering.isBrokerPriceHidden
                ) && !(isImpluseForm || isGoodwillForm)
              "
            >
              <div
                v-if="
                  inputFields.license.module_type == 1 ||
                  inputFields.license.module_type > 1
                "
                class="form-group row dynamicField"
              >
                <label class="col-sm-2 col-form-label" for="amount"
                  >Amount</label
                >
                <div>
                  <input
                    type="number"
                    name="amount"
                    v-bind:value="inputFields.amount"
                    disabled
                    id="amount"
                    class="form-control mx-3"
                  />
                </div>
              </div>
            </span>

            <!-- clientID -->
            <span
              v-if="
                !(
                  isImpluseForm &&
                  extraFormState.conditionalRendering.isBrokerClientIdHidden
                )
              "
            >
              <div
                v-if="formPackage.clientIdLabelText.trim() != ''"
                class="form-group row"
              >
                <label class="col-sm-2 col-form-label" for="broker_client_id">{{
                  formPackage.clientIdLabelText
                }}</label>
                <div>
                  <input
                    v-model.lazy="inputFields.broker_client_id"
                    type="text"
                    id="broker_client_id"
                    name="broker_client_id"
                    class="form-control mx-3"
                  />
                </div>
              </div>
            </span>

            <!-- Referral Code -->
            <span
              v-if="
                !(
                  isImpluseForm &&
                  extraFormState.conditionalRendering.isRefferalHidden
                )
              "
            >
              <div
                v-if="inputFields.isReferal && inputFields.referalCode"
                class="form-group row"
              >
                <label class="col-sm-2 col-form-label" for="otp"
                  >Referral Code</label
                >
                <div>
                  <input
                    v-model.lazy="inputFields.referalCode"
                    type="text"
                    name="referalCode"
                    @change="fetchTheReferalEmail"
                    id="referalCode"
                    class="form-control mx-3"
                  />
                  <span
                    v-if="validation.isWrongReferalError"
                    class="text-danger errorText mx-3"
                    >You need to Enter correct Referral Code.</span
                  >
                  <span
                    v-if="isReferalCodeVerified"
                    class="text-success successText mx-3"
                  >
                    Referral Code Verified Successfully
                  </span>
                </div>
              </div>
            </span>
          </div>

          <div id="group-2">
            <div
              v-if="formPackage.formTermsNCoditionHtml"
              class="form-group row termNConditionSection"
            >
              <label class="col-sm-2 col-form-label" for="email"
                >Terms &amp; Conditions</label
              >
              <div>
                <div
                  class=""
                  id="terms_n_condition_textarea"
                  v-html="formPackage.formTermsNCoditionHtml"
                ></div>
              </div>
            </div>

            <div class="custom-control custom-checkbox my-1" style="">
              <label class="col-sm-2 col-form-label" for=""></label>

              <input
                type="checkbox"
                id="tnc"
                name="tnc"
                class="custom-control-input"
                v-model="inputFields.termsNCondition"
                style=""
                value="I have read carefully and accept the T&amp;C"
              />

              <label class="custom-control-label" for="tnc">
                <router-link
                  v-if="!isImpluseForm"
                  class="termsNConditionText"
                  :to="{ name: 'usagePolicyRoute' }"
                >
                  I have read carefully and accept the T&amp;C
                </router-link>
                <span v-else class="termsNConditionText"
                  >I have read carefully and accept the T&amp;C</span
                >
              </label>
            </div>
          </div>
          <hr v-if="!isImpluseForm && !isGoodwillForm" />

          <div class="otpContainer">
            <!---->
            <div v-if="isOtpSend" class="form-group row dynamicField">
              <label class="col-sm-2 col-form-label" for="otp">OTP</label>
              <div>
                <input
                  v-model.lazy.trim="inputFields.otp"
                  type="text"
                  name="otp"
                  id="otp"
                  class="form-control mx-3"
                  @change="otpVerify"
                />
                <span
                  v-if="validation.isOtpError && isOtpSend"
                  class="text-danger errorText mx-3"
                  >You need to Enter correct OTP.</span
                >
                <span
                  v-if="isOtpVerified"
                  class="text-success successText mx-3"
                >
                  OTP Verified Successfully
                </span>
              </div>
            </div>
          </div>

          <div v-if="isImpluseForm">
            <impluse-iframe />

            <!-- <component :is="isImpluseForm" /> -->
          </div>

          <div class="bd-example" id="button_group">
            <button @click="resetForm" class="btn btn-primary" type="reset">
              Reset
            </button>

            <button
              style="margin-left: 5px"
              class="btn btn-primary"
              type="submit"
              :disabled="isSubmitDisabled"
            >
              {{ isGoodwillForm ? "Register" : "Submit" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import ImpluseIframe from "../extraFields/ImpluseIframe"
import ImpluseIframe from "../extraFields/ImpluseIframe.vue";
import { emailFormat } from "../exceptionEmailFormats/impluseEmailFormat.js";
import { impulseFormData } from "../state/extraFields/stateImplueIframe";
import { isFormValid } from "../hooks/extraField/ImpluseIframe";
export default {
  name: "ApiBridgeRegisterForm",
  props: ["formDynamicData", "ImpluseIframe", "formName"],
  components: { ImpluseIframe },
  async mounted() {
    this.formPackage.formIdentifier = this.formName;
    const form_name = this.formName;

    const formDataValue = this.formDynamicData;
    if (formDataValue != false && formDataValue != {}) {
      this.setFormDataValue(formDataValue);
      // await this.getAllLicence();
      this.formPackage.isFormLoaded = true;

      if (this.inputFields.isReferal) {
        const referal_code = this.getReferalCode();
        if (referal_code) {
          this.inputFields.referalCode = referal_code.trim();
        }
        this.fetchTheReferalEmail();
      }
    }
    return;

    return;
  },

  data() {
    return {
      extraFormState: impulseFormData,
      formPackage: {
        price: 0,
        usernamePrefix: "",
        formLoadedErrorText: "",
        formIdentifier: "",
        successMessage: "",
        successRedirectUrl: "",
        clientIdLabelText: "",
        formHeaderText: "",
        formSubHeaderText: "",
        formTermsNCoditionHtml: "",
        isFormLoadedError: false,
        isFormLoaded: false,
      },

      brokerBaseURI: "",
      inputFields: {
        supportEmail: "support@algoji.com",
        userId: 0,
        featureIds: "",
        name: "",
        username: "",
        email: "",
        phone: "",
        brokersOptions: [],
        licensesOptions: [],
        isReferal: true,
        stragedyPlatform: "",
        termsNCondition: "",
        license: "PaperTrading",
        amount: 0,
        otp: "",
        referalEmail: "",
        broker: {},
        referalCode: "",
        totalLicense: 1,
        module: 1,
        errorToBeDisplayed: false,
        broker_client_id: "",
        stragedyPlatformArr: [],
      },

      validationRegex: {
        email:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        mobile: /^[6789]\d{9}$/,
      },
      paymentObj: {
        token: "",
      },
      AUTH_DETAILS: {
        ADMIN_API_TOKEN: "",
        API_TOKEN: "",
        Admin_id: "",
      },
      userRegisterId: false,
      isUserExist: false,
      api_base_url: process.env.VUE_APP_API_URL,
      demo_api_base_url: process.env.VUE_APP_DEMO_API_URL,

      admin_base_url: process.env.VUE_APP_ADMIN_API_URL,
      validation: {
        isNameError: false,
        isEmailError: false,
        isPhoneError: false,
        isTnCError: false,
        isOtpError: false,
        isWrongReferalError: false,
      },
      isBrokerDisplayed: false,
      isAmountDisplayed: false,

      otpToken: "",
      isReferalCodeVerified: false,
      isOtpVerified: false,
      isOtpSend: false,

      logId: 0,
      proxyUrl: "",
    };
  },
  computed: {
    brokersListArr() {
      return this.inputFields.brokersOptions.map((x) => ({
        text: x.BrokerName,
        value: x,
      }));
    },
    isSubmitDisabled() {
      const isExtraFieldsValid = isFormValid(
        this.isImpluseForm,
        this.extraFormState
      );
      return (
        !this.isOtpVerified ||
        this.validation.isNameError ||
        this.isUserExist ||
        !this.inputFields.termsNCondition ||
        this.validation.isPhoneError ||
        !this.inputFields.phone ||
        !this.inputFields.name ||
        !isExtraFieldsValid
      );
    },
    isCityNameError() {},
    getImpluseFormData() {
      if (this.isImpluseForm) {
        const obj = {};
        const allProps = this.extraFormState.propsKeyArr;
        allProps.forEach((key) => (obj[key] = this.extraFormState[key]));
        return obj;
      }
      return {};
    },

    //
    // currentEditForm() {
    isImpluseForm() {
      if (this.formName == "impulse") {
        return true;
        // return () => import('../extraFields/ImpluseIframe.vue')
      }
      return false;
    },
    isAlgojiForm() {
      if (this.formName == "algoji") {
        return true;
        // return () => import('../extraFields/ImpluseIframe.vue')
      }
      return false;
    },
    isGoodwillForm() {
      return this.formName == "goodwill";
    },
    isSASonlineForm() {
      if (this.formName == "sasonline") {
        return true;
      }
      return false;
    },
    isProfitmartForm() {
      if (this.formName == "profitmart") {
        return true;
      }
      return false;
    },
    isJainamForm() {
      if (this.formName == "jainam") {
        return true;
      }
      return false;
    },
    isRmoneyForm() {
      if (this.formName == "rmoney") {
        return true;
      }
      return false;
    },
    isAsthaForm() {
      if (this.formName == "astha") {
        return true;
      }
      return false;
    },
    isAliceBlueForm() {
      if (this.formName == "aliceblue") {
        return true;
      }
      return false;
    },
    isImportantBrokerSelected() {
      if (
        !!this.inputFields?.broker?.BrokerName &&
        this.inputFields?.broker?.BrokerName != undefined
      ) {
        if (this.inputFields?.broker?.BrokerName.includes("*")) {
          return true;
        }
      }
      return false;
    },
    //
    //
  },

  methods: {
    async fetchTheReferalEmail() {
      this.validation.isWrongReferalError = false;
      this.isReferalCodeVerified = false;
      this.inputFields.referalEmail = "";
      if (this.inputFields.referalCode) {
        const fetchReferalUrl = `${this.proxyUrl}${this.api_base_url}api/forms/FetchReferalEmail`;
        const jsonHeader = this.getJsonHeaders();
        jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
        const requestObj = {
          headers: jsonHeader,
          method: "POST",
          body: JSON.stringify({
            code: this.inputFields.referalCode,
          }),
          redirect: "follow",
        };

        const referalDetailResp = await fetch(fetchReferalUrl, requestObj);
        const referalDetailJson = await referalDetailResp.json();
        const referalDetail = referalDetailJson.data;

        const isreferalCodeExist = referalDetail.isCodeExist;
        const referalEmail = referalDetail.referalEmail;
        this.inputFields.referalEmail = referalEmail;
        this.validation.isWrongReferalError = !isreferalCodeExist;
        this.isReferalCodeVerified = isreferalCodeExist;
      }
    },
    setFormDataValue(formData) {
      this.inputFields.licenceArr = [];
      this.AUTH_DETAILS.API_TOKEN = "Bearer " + formData.Token;
      this.AUTH_DETAILS.ADMIN_API_TOKEN = "Bearer " + formData.Admin.Token;
      this.AUTH_DETAILS.Admin_id = formData.Admin.AdminID;

      this.formPackage.successMessage = formData.success_message_str;
      this.formPackage.successRedirectUrl = formData.redirect_url;
      this.formPackage.usernamePrefix = formData.username_prefix_str;
      this.formPackage.price = formData.amount;

      this.inputFields.brokersOptions = formData.BrokerList;
      this.inputFields.licenceArr = formData.LicenseList;

      this.formPackage.clientIdLabelText = formData.label_client_id;
      this.formPackage.formHeaderText = formData.header;
      this.formPackage.formSubHeaderText = formData.subheader;
      this.formPackage.formTermsNCoditionHtml = formData.tnc_text;
      this.inputFields.isReferal = formData.is_referal == 1 ? true : false;
      this.inputFields.stragedyPlatformArr = formData.StrategysList;

      if (this.inputFields.brokersOptions.length) {
        // sort function
        this.inputFields.brokersOptions.sort(function (a, b) {
          let x = a.BrokerName.toLowerCase();
          let y = b.BrokerName.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        if (
          this.formName !== "goodwill" &&
          this.formName !== "impulse" &&
          this.formName !== "sasonline" &&
          this.formName !== "profitmart" &&
          this.formName !== "jainam" &&
          this.formName !== "rmoney" &&
          this.formName !== "astha" &&
          this.formName !== "aliceblue"
        ) {
          this.inputFields.brokersOptions.unshift({
            BrokerName: "Select Broker",
            BrokerUrl: "",
            BrokerValue: "",
          });
        }

        this.inputFields.broker = this.inputFields.brokersOptions[0];
      }
      if (this.inputFields.stragedyPlatformArr.length) {
        this.inputFields.stragedyPlatform =
          this.inputFields.stragedyPlatformArr[0];
      }
      if (this.inputFields.licenceArr.length) {
        this.inputFields.license = this.inputFields.licenceArr[0];
        this.licenseChange();
        // this.inputFields.amount = parseInt(this.inputFields.license.amount)
      }
    },
    async logUserCreateError(errorText) {
      const logErrorUrl = `${this.proxyUrl}${this.api_base_url}/api/forms/logError`;
      const jsonHeader = this.jsonHeader();
      const errorMessage = "ERROR while Processing Form <br>" + errorText;
      const reqObj = {
        redirect: "follow",
        method: "POST",
        headers: jsonHeader,
        body: JSON.stringify({
          message: errorMessage,
          subject: "Critical: APIBridge Registration Error",
        }),
      };
      const logErrorReq = await fetch(logErrorUrl, reqObj);
    },
    async paymentHandler(...params) {
      params = params[0];
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const {
        razorpay_order_id: orderId,
        razorpay_signature: signatureId,
        razorpay_payment_id: paymentId,
      } = params;
      const requestObj = {
        headers: jsonHeader,
        body: JSON.stringify({
          orderId,
          signatureId,
          paymentId,
        }),
        method: "POST",
        redirect: "follow",
      };
      const paymentVerificationUrl = `${this.proxyUrl}${this.api_base_url}api/forms/PaymentVerification`;
      const responseObj = await fetch(paymentVerificationUrl, requestObj);
      const verificationRespObj = await responseObj.json();
      const isPaymentVerified = verificationRespObj.data;

      if (isPaymentVerified) {
        await this.updateUserLog({
          orderId,
          signatureId,
          paymentId,
          adminApiUserName: this.AUTH_DETAILS.username,
        });

        const isUserCreated = await this.createNewUser(); // Create User Comment

        if (isUserCreated) {
          // const isAcknowldgeMailSend = await this.sendAcknowldgementMail(); // Email Send Uncomment
          let isAcknowldgeMailSend;

          if (this.isImportantBrokerSelected) {
            isAcknowldgeMailSend = await this.sendAcknowldgementMail(
              this.inputFields.email,
              this.inputFields.supportEmail
            );
            if (
              !!this.inputFields.referalEmail &&
              this.inputFields.referalEmail != null &&
              this.inputFields.referalEmail != undefined
            ) {
              this.sendAcknowldgementMail(
                this.inputFields.referalEmail.trim(),
                ""
              );
            }
          } else {
            isAcknowldgeMailSend = await this.sendAcknowldgementMail();
          }

          if (isAcknowldgeMailSend) {
            this.processUserSuccess();
          }
        }
      } else {
        this.$swal({
          title: "Something went wrong",
          text: "Unable to do Payment",
          icon: "error",
        });
      }
    },

    async processUserSuccess() {
      await this.$swal({
        title: "",
        text: this.formPackage.successMessage,
        icon: "success",
        backdrop: true,
        allowOutsideClick: false,
      });
      if (this.formPackage.successRedirectUrl.trim()) {
        window.location = this.formPackage.successRedirectUrl;
      }
    },
    getRandomStr(length) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    async checkIsUserExist() {
      const isPhoneValid = !this.validatePhone();

      const requestHeaders = new Headers();
      requestHeaders.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      if (isPhoneValid) {
        const userCode = this.getUserCode();
        const randomStr = this.getRandomStr(4);
        if (this.isAlgojiForm) {
          this.inputFields.username = `${this.formPackage.usernamePrefix}${randomStr}`;
        } else {
          this.inputFields.username = `${this.formPackage.usernamePrefix}${userCode}`;
        }

        const isUserExistApiUrl = `${this.proxyUrl}${this.admin_base_url}/Admin/IsUserNameAvailable?username=${this.inputFields.username}`;

        const resp = await fetch(isUserExistApiUrl, {
          headers: requestHeaders,
          redirect: "follow",
        });
        const isUserExist = await resp.json();
        const isUniqueCode = isUserExist.code == 200;
        this.isUserExist = !isUniqueCode;

        if (this.isUserExist && this.isAlgojiForm) {
          await this.checkIsUserExist();
        }
      } else {
        this.isUserExist = false;
        return false;
      }
    },
    getGETParamValue(str) {
      let urlObj = new URL(window.location.href);
      const code = urlObj.searchParams.get(str);
      if (code) {
        return code;
      }
      return "";
    },
    getReferalCode() {
      let urlObj = new URL(window.location.href);
      const code = urlObj.searchParams.get("r");

      if (code) {
        return code;
      }
      return "";
    },
    getUserCode() {
      return this.inputFields.phone.substring(4, 10);
    },
    resetForm(evt) {
      window.location.reload();
      if (this.$refs.registerFormElem) {
        this.$refs.registerFormElem.reset();
      }
    },
    validatePhone() {
      let isError = false;
      if (this.inputFields.phone == "") {
        isError = true;
      }
      if (!this.validationRegex.mobile.test(this.inputFields.phone)) {
        isError = true;
      }
      this.validation.isPhoneError = isError;
      return isError;
    },

    validateEmail() {
      let isError = false;
      if (this.inputFields.email == "") {
        isError = true;
      }
      if (!this.validationRegex.email.test(this.inputFields.email)) {
        isError = true;
      }
      this.validation.isEmailError = isError;
      return isError;
    },
    validateName() {
      let isError = false;
      if (this.inputFields.name == "") {
        isError = true;
        this.validation.isNameError = true;
      } else {
        isError = false;
        this.validation.isNameError = false;
      }
      this.validation.isEmailError = isError;
      return isError;
    },
    async validateForm() {
      let isError = false;
      if (this.inputFields.name == "") {
        isError = true;
        this.validation.isNameError = true;
      } else {
        this.validation.isNameError = false;
      }

      isError = this.validatePhone();
      isError = this.validateEmail();

      if (this.inputFields.termsNCondition == false) {
        isError = true;
        this.validation.isTnCError = true;
        await this.$swal({
          title: "Terms n Condition",
          text: "Please Accept Terms And Condition",
          icon: "error",
        });
      } else {
        this.validation.isTnCError = false;
      }

      if (this.inputFields.otp == "" || !this.isOtpVerified) {
        isError = true;
        this.validation.isOtpError = true;
      } else {
      }

      if (
        this.inputFields.broker == "" ||
        this.inputFields.broker.BrokerName == "Select Broker"
      ) {
        isError = true;
      }

      return isError;
    },

    getRegisterUserObj() {
      const FinalObj = {
        name: this.inputFields.name,
        username: this.inputFields.username,
        phone: this.inputFields.phone,
        email: this.inputFields.email,
        module_text: this.inputFields.license.license_name, // as a text Live Trade , "Paper Trading"
        referal_code: this.inputFields.referalCode,
        module_id: this.inputFields.license.module_type,
        broker_id_text: this.inputFields.broker.BrokerName, // Broker name as text
        broker_id: this.inputFields.broker.BrokerValue,
        form_identifier: this.formPackage.formIdentifier,
        stragedyPlatform: this.inputFields.stragedyPlatform,
        broker_client_id: this.inputFields.broker_client_id,
        number_of_license: this.inputFields.license.number_of_license,
        payment_verification_code: null, //papertrading "null" , livetrading :Payment done : "success" || "failure",
        order_id: null,
        payment_id: null,
        amount: this.inputFields.amount,
        referalEmail: this.inputFields.referalEmail,
        payment_signature: "",
      };

      return FinalObj;
    },
    getJsonHeaders() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },
    async updateUserLog(paymentObj) {
      const User = this.getRegisterUserObj();
      User.payment_verification_code = paymentObj.signatureId;
      User.order_id = paymentObj.orderId;
      User.payment_id = paymentObj.paymentId;
      User.payment_status = "success";

      const UserStr = JSON.stringify(User);
      const UserPayload = JSON.stringify({
        insertJson: UserStr,
        recordID: this.logId,
      });
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const updateUserLogUrl = `${this.proxyUrl}${this.api_base_url}api/forms/UpdatepaymentStatus`;
      const isRegisteredUserUpdatedResp = await fetch(updateUserLogUrl, {
        method: "POST",
        headers: jsonHeader,
        body: UserPayload,
        redirect: "follow",
      });
      const isRegisteredUserUpdatedRespJson =
        await isRegisteredUserUpdatedResp.json();

      const isLogUpdated =
        isRegisteredUserUpdatedRespJson.data.includes("Record Updated! ID");
      return isLogUpdated;
    },
    async submitNewUserLog() {
      this.logId = 0;
      const newUser = this.getRegisterUserObj();
      if (this.isImpluseForm) {
        newUser.extraFields = this.getImpluseFormData;
      }
      const newUserStr = JSON.stringify(newUser);
      const newUserPayload = JSON.stringify({
        insertJson: newUserStr,
      });
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const insertUserLogUrl = `${this.proxyUrl}${this.api_base_url}api/forms/InsertInto`;
      const isRegisteredUserInsertedResp = await fetch(insertUserLogUrl, {
        method: "POST",
        headers: jsonHeader,
        body: newUserPayload,
        redirect: "follow",
      });
      const isRegisteredUserInsertedRespJson =
        await isRegisteredUserInsertedResp.json();

      this.logId = isRegisteredUserInsertedRespJson.data.split(":")[1];
      const isLogInserted = this.logId > 0;
      return isLogInserted;
    },
    async createNewUser() {
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      const payloadData = {
        Username: this.inputFields.username,
        Name: this.inputFields.name,
        Email: this.inputFields.email,
        Mobile: this.inputFields.phone,
        ModuleID: this.inputFields.module,
        Password: this.inputFields.username,
        NumberOfLicense: this.inputFields.license.number_of_license,
        AdminBrokerID: this.inputFields.broker.BrokerValue,
        AdminID: this.AUTH_DETAILS.Admin_id,
        BrokerBaseURI: this.inputFields.broker.BrokerUrl,
      };
      if (this.inputFields.isReferal && this.inputFields.referalCode) {
        payloadData.ReferralCode = this.inputFields.referalCode;
      }
      if (this.isImpluseForm) {
        // payloadData.Features = "STRAT1,STRAT2";
      }
      // Remove this

      const payloadObj = JSON.stringify(payloadData);

      const isRegisteredUserInsertedResp = await fetch(
        `${this.proxyUrl}${this.admin_base_url}/Admin/registerUser`,
        {
          method: "POST",
          headers: jsonHeader,
          body: payloadObj,
          redirect: "follow",
        }
      );
      const isRegisteredUserInsertedRespObj =
        await isRegisteredUserInsertedResp.json();

      const isUserRegistered =
        isRegisteredUserInsertedRespObj.message == "User Added";

      if (isUserRegistered) {
        this.userRegisterId = isRegisteredUserInsertedRespObj.data.UserID;
      }

      return isUserRegistered;
    },
    async sendAcknowldgementMail(
      email = this.inputFields.email,
      RefEmail = this.inputFields.referalEmail.trim()
    ) {
      let emailSendUrl = `${this.proxyUrl}${this.api_base_url}/api/forms/SendAck`;

      // let x = {name:this.inputFields.name , "username": this.inputFields.username ,"password": this.inputFields.username ,  email: this.inputFields.email  , phone: this.inputFields.phoneNumber , }
      let obj = {};
      obj = {
        Username: this.inputFields.username,
        Email: email,
        License: this.inputFields.license.license_name,
        RefEmail,
        Password: this.inputFields.username,
        Name: this.inputFields.name,
        Mobile: this.inputFields.phone,
      };
      if (this.inputFields.brokersOptions.length > 1) {
        obj.Broker = this.inputFields.broker.BrokerName;
      }

      const payload = JSON.stringify(obj);

      const headers = this.getJsonHeaders();
      headers.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const ismailSendResp = await fetch(emailSendUrl, {
        headers,
        method: "POST",
        body: payload,
        redirect: "follow",
      });
      const ismailSendRespObj = await ismailSendResp.json();
      const ismailSend =
        ismailSendRespObj.data.trim() ===
        " The email was sent successfully.".trim();
      return ismailSend;
    },
    async fetchUserId() {
      const AuthURL = `${this.api_base_url}/api/Login/SubsUser`;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const credsGeneratedString = JSON.stringify({
        Username: this.inputFields.username,
        Password: this.inputFields.username,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: credsGeneratedString,
        redirect: "follow",
      };

      const resp = await fetch(AuthURL, requestOptions);
      const respJson = await resp.json();
      this.inputFields.userId = respJson.UserId;
      return respJson.UserId;
    },
    async updateImpluseFeature(UserID) {
      var myHeaders = new Headers();
      myHeaders.append("authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID,
        FeatureIDs: "559,560,526",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://api.algoji.com/api/Admin/assignUserFeatures",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => result)
        .catch((error) => console.log("error", error));
    },
    async mailImpulseUserDetailToAdmin() {
      if (!this.isImpluseForm) return false;

      const emailSendUrl = `${this.proxyUrl}${this.api_base_url}/api/Forms/SendMail`;
      let obj = {
        name: this.inputFields.name,
        username: this.inputFields.username,
        password: this.inputFields.username,
        email: this.inputFields.email,
        phone: this.inputFields.phone,
      };
      obj = { ...obj, ...this.getImpluseFormData };
      const emailStr = emailFormat.getHtmlStr(obj);
      obj = {
        Recipient: emailFormat.recipentEmail,
        Subject: emailFormat.subject,
        Sender: "no-reply@apibridge.app",
        Body: emailStr,
      };
      const payload = JSON.stringify(obj);
      const headers = this.getJsonHeaders();
      headers.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const ismailSendResp = await fetch(emailSendUrl, {
        headers,
        method: "POST",
        body: payload,
        redirect: "follow",
      });
      const ismailSendRespObj = await ismailSendResp.json();
    },
    async formSubmit(evt) {
      const isError = await this.validateForm();
      await this.otpVerify(); //VerifyOtp
      const isOtpVerified = this.isOtpVerified;
      if (!isOtpVerified && this.isOtpSend) {
        await this.$swal({
          title: "OTP Error",
          text: "You need to Enter OTP",
          icon: "error",
          backdrop: true,
          allowOutsideClick: false,
        });
      }
      if (this.inputFields.isReferal) {
        if (this.validation.isWrongReferalError) {
          await this.$swal({
            title: "Please Enter a Referral Code",
            text: "You need to Enter Correct Referacl Code",
            icon: "error",
            backdrop: true,
            allowOutsideClick: false,
          });
          return;
        }
      }
      if (isError) {
        this.$swal({
          title: "Form Not Valid",
          text: "Please Fill All the Manadatory Fields",
          icon: "error",
        });
        return;
      } else {
        // await this.sendAcknowldgementMail();
        // if (this.isImpluseForm) this.mailImpulseUserDetailToAdmin();
        // return ;
        debugger;
        const isPaperTrading = this.inputFields.module == 1;
        const isNewUserLogInserted = await this.submitNewUserLog();
        if (isPaperTrading) {
          const isUserCreated = await this.createNewUser(); //Create User Comment
          if (isUserCreated) {
            if (this.isImpluseForm) {
              const userId = await this.fetchUserId();
              await this.updateImpluseFeature(userId);
            }
            let isAcknowldgeMailSend;

            if (this.isImportantBrokerSelected) {
              isAcknowldgeMailSend = await this.sendAcknowldgementMail(
                this.inputFields.email,
                this.inputFields.supportEmail
              );
              if (
                !!this.inputFields.referalEmail &&
                this.inputFields.referalEmail != null &&
                this.inputFields.referalEmail != undefined
              ) {
                this.sendAcknowldgementMail(
                  this.inputFields.referalEmail.trim(),
                  ""
                );
              }
            } else {
              isAcknowldgeMailSend = await this.sendAcknowldgementMail();
            }
            if (this.isImpluseForm) this.mailImpulseUserDetailToAdmin();

            if (isAcknowldgeMailSend) {
              this.processUserSuccess();
            }
          }
        } 
	else if( Number(this.inputFields.module) == 2 &&  Number(this.inputFields.amount) == 0  ){
          const isUserCreated = await this.createNewUser(); //Create User Comment
          if (isUserCreated) {
            if (this.isImpluseForm) {
              const userId = await this.fetchUserId();
              await this.updateImpluseFeature(userId);
            }
            let isAcknowldgeMailSend;

            if (this.isImportantBrokerSelected) {
              isAcknowldgeMailSend = await this.sendAcknowldgementMail(
                this.inputFields.email,
                this.inputFields.supportEmail
              );
              if (
                !!this.inputFields.referalEmail &&
                this.inputFields.referalEmail != null &&
                this.inputFields.referalEmail != undefined
              ) {
                this.sendAcknowldgementMail(
                  this.inputFields.referalEmail.trim(),
                  ""
                );
              }
            } else {
              isAcknowldgeMailSend = await this.sendAcknowldgementMail();
            }
            if (this.isImpluseForm) this.mailImpulseUserDetailToAdmin();

            if (isAcknowldgeMailSend) {
              this.processUserSuccess();
            }
          }
        }
        else {
          const paymentObj = await this.fetchPaymentObj();

          paymentObj.handler = this.paymentHandler;

          const rzp1 = new Razorpay(paymentObj);
          rzp1.open();
        }
      }
    },
    async fetchPaymentObj() {
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const payload = JSON.stringify({
        name: this.inputFields.name,
        email: this.inputFields.email,
        phone: this.inputFields.phone,
        amount: this.inputFields.amount * 100,
        username: this.inputFields.username,
        adminApiUserName: this.AUTH_DETAILS.username,
      });
      const requestObj = {
        method: "POST",
        headers: jsonHeader,
        body: payload,
        redirect: "follow",
      };

      const paymentRespUrl = `${this.proxyUrl}${this.api_base_url}/api/forms/CreateRazorPayOrder`;
      const paymentObjResp = await fetch(paymentRespUrl, requestObj);
      const paymentObj = await paymentObjResp.json();

      return paymentObj.data;
    },
    async getAllLicence() {
      this.inputFields.licenceArr = [];
      const getAllLicenceUrl = `${this.proxyUrl}${this.api_base_url}api/forms/FetchLicenseList`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const reqObj = {
        headers: jsonHeader,
        method: "GET",
        redirect: "follow",
      };
      const getLicenceResp = await fetch(getAllLicenceUrl, reqObj);

      const getLicenceJson = await getLicenceResp.json();
      const licenceArr = getLicenceJson.data;

      this.inputFields.licenceArr = licenceArr;
      if (this.inputFields.licenceArr.length) {
        this.inputFields.license = this.inputFields.licenceArr[0];
      }
    },
    licenseChange() {
      this.inputFields.module = this.inputFields.license.module_type;
      this.inputFields.totalLicense =
        this.inputFields.license.number_of_license;
      if (this.inputFields.module > 1) {
        this.inputFields.amount = parseInt(this.inputFields.license.amount);
      } else {
        this.inputFields.amount = 0;
      }
    },

    async emailOtpSend() {
      const isEmailValid = !this.validateEmail();
      this.isOtpSend = false;
      this.isOtpVerified = false;
      if (isEmailValid) {
        const payload = JSON.stringify({
          Name: this.inputFields.name,
          Email: this.inputFields.email,
        });
        const otpSendUrl = `${this.proxyUrl}${this.api_base_url}api/forms/RequestOTP`;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", this.AUTH_DETAILS.API_TOKEN);

        const resp = await fetch(otpSendUrl, {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: payload,
        });
        const respObj = await resp.json();
        this.isOtpSend = respObj.code == 200 && respObj.data != "";
        if (this.isOtpSend) {
          this.otpToken = respObj.data;
        }
      } else {
        //Display Error
      }
    },
    async otpVerify() {
      this.isOtpVerified = false;
      if (this.otpToken && this.isOtpSend && this.inputFields.otp) {
        const verifyOTPUrl = `${this.proxyUrl}${this.api_base_url}api/forms/VerifyOTP`;

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${this.otpToken}`);
        headers.append("Content-Type", "application/json");

        const body = JSON.stringify({
          OTP: this.inputFields.otp,
        });
        const requestObject = {
          redirect: "follow",
          body,
          headers,
          method: "POST",
        };
        const verifyResp = await fetch(verifyOTPUrl, requestObject);
        const verifyRespObj = await verifyResp.json();
        this.isOtpVerified = verifyRespObj.data == "OTP is correct!";
      }
      this.validation.isOtpError = !this.isOtpVerified;
    },
  },
};
</script>


<style scoped>
[type="submit"] {
  cursor: pointer;
}

.otpContainer {
  /* margin-top:10px; */
}

.subHeaderText {
  padding-bottom: 10px;
}

.btn:disabled {
  cursor: not-allowed;
}

#group-2 {
  margin-bottom: 20px;
}

.termsNConditionText {
  color: black;
  padding-left: 5px;
}

.termNConditionSection {
  margin-bottom: 5px;
}
</style>
